import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/howToUse',
    name: 'howToUse',
    redirect: "doc",
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/doc/:article?',
    name: 'doc',
    component: () => import('../views/Doc.vue')
  },
  {
    path: '/how-to-publish/:article?',
    name: 'publish',
    component: () => import('../views/HowToPublish.vue')
  },
  {
    path: '/cookiesPolicy',
    name: 'cookiesPolicy',
    component: () => import('../views/CookiesPolicy.vue')
  },
  {
    path: '/preview/:id/:orientation?',
    name: "preview",
    component: () => import('../views/Preview.vue')
  },
  {
    path: "/blog/:article?",
    name: "blog",
    component: () => import('../views/Blog.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(), // createWebHashHistory
  routes
})

export default router
