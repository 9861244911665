
import { Options, Vue } from 'vue-class-component';
import ApiService from '@/services/ApiService';
import { PlayableAdsResponse, PlayableAd } from '@/services/ApiService';
import PlayableAdItem from './PlayableAdItem.vue';

@Options({
  components: {
    PlayableAdItem
  },
})
export default class AdList extends Vue {
  private apiService!: ApiService;
  public playables: PlayableAd[] = [];
  
  mounted(): void {
    this.apiService = new ApiService();
    this.apiService.getPlayableAds().then((result: PlayableAdsResponse)=> {
      this.playables = result.playables;
    }).catch(error => {
      //this.errorMessage = error;
      console.error("There was an error!", error);
    });
  }
}
