import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "playable" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ContactFormDialog = _resolveComponent("ContactFormDialog")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_img, {
        "max-height": "667",
        "max-width": "375",
        src: _ctx.getUrl(_ctx.playable.preview),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPlayable(_ctx.playable)))
      }, null, 8, ["src"]),
      _createElementVNode("p", null, _toDisplayString(_ctx.playable.description), 1),
      (_ctx.playable.store?.vpa)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            color: "primary",
            href: _ctx.playable.store.vpa,
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('btn.checkout.vpa')) + " ", 1),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "top"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('btn.item.vpa.tooltip')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true),
      (_ctx.playable.store?.codester)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 1,
            color: "primary",
            href: _ctx.playable.store.codester,
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('btn.checkout.codester')), 1)
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true),
      (_ctx.playable.store?.free)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 2,
            color: "secondary",
            href: _ctx.getUrl(_ctx.playable.store.free)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('btn.downloadFree')), 1)
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true),
      (_ctx.playable.store?.wip)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 3,
            disabled: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('btn.item.wip')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.playable.store)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 4,
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openContactDialog()), ["stop"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('btn.item.ask')) + " ", 1),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "top"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('btn.item.ask.tooltip')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ContactFormDialog, { show: _ctx.contactDialog }, null, 8, ["show"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.dialogData.open,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogData.open) = $event)),
      width: `${_ctx.dialogData.width}px`,
      height: `${_ctx.dialogData.height}px`
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_btn, {
          class: "white--text btn-close",
          fab: "",
          icon: "",
          small: "",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode("mdi-close")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("iframe", {
          src: _ctx.getUrl(_ctx.playable.src),
          width: "100%",
          height: "100%",
          frameborder: "0"
        }, "\n    ", 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["modelValue", "width", "height"])
  ], 64))
}