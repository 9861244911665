import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "products-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ContactFormDialog = _resolveComponent("ContactFormDialog")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.products')), 1),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { dense: "" }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_v_col, {
              key: 2,
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, {
                      textContent: _toDisplayString(_ctx.$t('home.products.vpa')),
                      style: {"background-color":"rgb(var(--v-theme-secondary))","color":"#000"}
                    }, null, 8, ["textContent"]),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, { lines: "one" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              title: _ctx.$t('home.products.vpa.list.1')
                            }, null, 8, ["title"]),
                            _createVNode(_component_v_list_item, {
                              title: _ctx.$t('home.products.vpa.list.2'),
                              subtitle: _ctx.$t('home.products.vpa.list.2.sub')
                            }, null, 8, ["title", "subtitle"]),
                            _createVNode(_component_v_list_item, {
                              title: _ctx.$t('home.products.vpa.list.3')
                            }, null, 8, ["title"]),
                            _createVNode(_component_v_list_item, {
                              class: "list-item-bold",
                              title: _ctx.$t('home.products.vpa.list.4'),
                              subtitle: _ctx.$t('home.products.vpa.list.4.sub'),
                              href: 'https://playablemaker.com',
                              target: "_blank"
                            }, null, 8, ["title", "subtitle", "href"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, { style: {"justify-content":"center"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          color: "secondary",
                          variant: "elevated",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openVPAEditor()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('home.products.vpa.action')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })),
            (_openBlock(), _createBlock(_component_v_col, {
              key: 3,
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, {
                      textContent: _toDisplayString(_ctx.$t('home.products.custom'))
                    }, null, 8, ["textContent"]),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, { lines: "one" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              title: _ctx.$t('home.products.custom.list.1')
                            }, null, 8, ["title"]),
                            _createVNode(_component_v_list_item, {
                              title: _ctx.$t('home.products.custom.list.2'),
                              subtitle: _ctx.$t('home.products.custom.list.2.sub')
                            }, null, 8, ["title", "subtitle"]),
                            _createVNode(_component_v_list_item, {
                              title: _ctx.$t('home.products.custom.list.3')
                            }, null, 8, ["title"]),
                            _createVNode(_component_v_list_item, {
                              class: "list-item-bold",
                              title: _ctx.$t('home.products.custom.list.4'),
                              subtitle: _ctx.$t('home.products.custom.list.4.sub')
                            }, null, 8, ["title", "subtitle"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, { style: {"justify-content":"center"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          color: "primary",
                          variant: "elevated",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openContactDialog()), ["stop"]))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('home.products.custom.action')) + " ", 1),
                            _createVNode(_component_ContactFormDialog, { show: _ctx.dialog }, null, 8, ["show"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}