
import { Options, Vue } from 'vue-class-component';
import Products from '@/components/Products.vue';

@Options({
  components: {
    Products
  },
  props: { }
})
export default class Intro extends Vue {
  public dialog: boolean = false;
  openCodester() {
    window.open('https://www.codester.com/randspot/shop', '_blank');
  }
}
