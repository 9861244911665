import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.show) = $event)),
      activator: "parent",
      width: "auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar, {
              color: "primary",
              title: "Let`s meet and create"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  onClick: _ctx.close
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-close")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          style: {"max-width":"600px","text-align":"center"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('contactForm.info')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.$t('contactForm.name'),
                              variant: "outlined",
                              modelValue: _ctx.name,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                              disabled: _ctx.working,
                              required: ""
                            }, null, 8, ["label", "modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              label: _ctx.$t('contactForm.email'),
                              variant: "outlined",
                              modelValue: _ctx.email,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                              rules: _ctx.emailRules,
                              disabled: _ctx.working,
                              required: ""
                            }, null, 8, ["label", "modelValue", "rules", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_textarea, {
                              label: _ctx.$t('contactForm.message'),
                              variant: "outlined",
                              modelValue: _ctx.message,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.message) = $event)),
                              disabled: _ctx.working,
                              required: ""
                            }, null, 8, ["label", "modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          style: {"text-align":"center"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_progress_linear, {
                              class: _normalizeClass(["progress-base", { progress: _ctx.working }]),
                              "v-model": _ctx.working,
                              indeterminate: ""
                            }, null, 8, ["class", "v-model"]),
                            _createVNode(_component_v_btn, {
                              color: "primary",
                              disabled: !_ctx.name || !_ctx.email || !_ctx.message || _ctx.working,
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitDialog()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('contactForm.submit')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.showSnackbar,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showSnackbar) = $event)),
      color: "primary",
      "multi-line": "",
      timeout: 7000
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "red",
          variant: "text",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showSnackbar = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('btn.close')), 1)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.snackBarText) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}