import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueGtag from 'vue-gtag';
import { VueCookieNext } from 'vue-cookie-next';

import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
        mdi,
    }
  },
  components,
  directives,
})

import i18n from './i18n'


const app = createApp(App).use(i18n).use(store).use(router).use(VueCookieNext).use(vuetify);

app.use(VueGtag, { config: { id: 'G-JCP78WEMMF' }, router, enabled: false });

// Will be used later
app.provide('gtag', app.config.globalProperties.$gtag);

app.mount('#app');