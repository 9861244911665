
import { Options, Vue } from 'vue-class-component';
import ApiService from '@/services/ApiService';
import { PlayableAd } from '@/services/ApiService';
import { type PropType } from 'vue'
import ContactFormDialog from './ContactFormDialog.vue';

const DEF_DIALOG_WIDTH = 375;
const DEF_DIALOG_HEIGHT = 667;

interface DialogData {
  open: boolean;
  width: number;
  height: number;
}

@Options({
  components: {
    ContactFormDialog
  },
  props: {
    playable: Object as PropType<PlayableAd>
  }
})
export default class PlayableAdItem extends Vue {
  
  public playable!: PlayableAd;
  public dialogData: DialogData = { open: false, width: DEF_DIALOG_WIDTH, height: DEF_DIALOG_HEIGHT };

  public contactDialog: boolean = false;

  mounted(): void {
    window.devicePixelRatio;
  }

  public getUrl(relative: string): string {
    return ApiService.getAbsoluteUrl(relative);
  }

  public openContactDialog() {
    this.contactDialog = true;
  }

  public openPlayable(playable: PlayableAd) {
    console.log("open playable " + playable.name);
    console.log(window.devicePixelRatio);
    this.dialogData.open = true;
  }

  public closeDialog() {
    this.dialogData.open = false;
  }
}
