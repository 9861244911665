export default {
  "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playable Ads Store"])},
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playables"])},
  "menu.howToEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use"])},
  "menu.howToPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to publish"])},
  "menu.contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "menu.cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies Policy"])},
  "btn.ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "btn.checkout.vpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PlayableMaker"])},
  "btn.checkout.codester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy on Codester"])},
  "btn.downloadFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Download"])},
  "btn.item.wip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available soon"])},
  "btn.item.ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "btn.item.ask.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ask via contact email. We can deliver this Playable Ad for you."])},
  "btn.item.vpa.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try out our PA Editor PlayableMaker to create Playable Ads."])},
  "btn.products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "btn.requestDemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Demo"])},
  "btn.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "home.bannerCookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies. For more details check our policy"])},
  "home.bannerCookiesLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "home-deprecated.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse through our latest Playable Ads"])},
  "home-deprecated.subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the best fit for your campaign and feel free to customize it to suit your needs."])},
  "home-deprecated.subheader.contact.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We can create a brand new Playable Ad specifically tailored to your requirements. If interested, please ", _interpolate(_list(0)), "."])},
  "home-deprecated.subheader.contact.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "home-deprecated.subheader.howto.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Before purchasing any Playable Ad, we recommend checking out our ", _interpolate(_list(0)), " section for helpful tips and guidelines."])},
  "home-deprecated.subheader.howto.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use"])},
  "home-deprecated.subheaderHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our playable Ads are build with our own build system, using Phaser 3, Typescript and webpack. If you need help with your Playable Ad to support the most used Ads platform, we can help you."])},
  "home.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost your campaign with Playable Ads!"])},
  "home.subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can create a brand new Playable Ad specifically tailored to your requirements, or you can easily select one of our ready-to-publish Playable Ad and customize it by yourself."])},
  "home.portfolio.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse through our latest Playable Ads"])},
  "home.platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Networks"])},
  "home.platformsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All our Playable Ads are compatible with the most used Ads networks. See below:"])},
  "home.products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our products"])},
  "home.products.premade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playable Ad templates"])},
  "home.products.premade.list.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premade 2D Playable Ad, ready to build"])},
  "home.products.premade.list.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customise it by yourself"])},
  "home.products.premade.list.2.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the documentation"])},
  "home.products.premade.list.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes all supported Ad networks"])},
  "home.products.premade.list.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price from 74$"])},
  "home.products.premade.list.4.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download from Codester"])},
  "home.products.premade.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show playable ads"])},
  "home.products.vpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PlayableMaker"])},
  "home.products.vpa.list.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Playable Ad from video"])},
  "home.products.vpa.list.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customise it by yourself"])},
  "home.products.vpa.list.2.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the editor with assets"])},
  "home.products.vpa.list.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes all supported Ad networks"])},
  "home.products.vpa.list.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it for free"])},
  "home.products.vpa.list.4.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["playablemaker.com"])},
  "home.products.vpa.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to PlayableMaker"])},
  "home.products.custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Playable Ad"])},
  "home.products.custom.list.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A custom 2D or 3D Playable Ad"])},
  "home.products.custom.list.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No work is needed from you"])},
  "home.products.custom.list.2.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will build the Playable Ad from your resources"])},
  "home.products.custom.list.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes all supported Ad networks"])},
  "home.products.custom.list.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price around 2000$"])},
  "home.products.custom.list.4.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time between 1 and 3 weeks"])},
  "home.products.custom.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Playable Ad"])},
  "contactForm.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're here to assist you in elevating your brand or product from great to extraordinary. Please complete the form below to provide us with more information about your project."])},
  "contactForm.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name*"])},
  "contactForm.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email*"])},
  "contactForm.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "contactForm.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "contactForm.wrongEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong email format."])},
  "contactForm.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for getting in touch! Your message has been received, and we will get back to you as soon as possible."])},
  "contactForm.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We apologize for the inconvenience, but it seems there was an issue processing your message through the contact form. Please don't hesitate to reach out to us directly via email at info[at]playable-ads.store."])},
  "contact.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "contact.subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have our own framework to create Playable Ads. We are able to create quickly a new Playable Ad regardless on Ads platform.<br/>All our Playable Ads are easily customisable, therefor are ready to create a lot of variation.<br/>We support multiple Ad networks: Facebook, Tiktok, Google, Ironsource, AppLovin and Unity."])},
  "preview.portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portrait"])},
  "preview.landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landscape"])},
  "cookiePolicy.allowCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable cookie for Google Analytics"])},
  "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "doc.missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested page is missing. Sorry for that :(<br/>We are working on documentation, we hope it will be soon finished. Feel free to contact us with your questions."])}
}