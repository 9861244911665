
import { Options, Vue } from 'vue-class-component';
import emailjs from '@emailjs/browser';

declare type ValidationResult = string | boolean;
declare type ValidationRule = ValidationResult | PromiseLike<ValidationResult> | ((value: any) => ValidationResult) | ((value: any) => PromiseLike<ValidationResult>);

@Options({
  props: {
    show: Boolean
  }
})
export default class ContactFormDialog extends Vue {
  public show: boolean = false;

  public name: string = "";
  public email: string = "";
  public message: string = "";

  public emailRules: ValidationRule[] = [];

  public snackBarText = "";
  public showSnackbar = false;

  public working: boolean = false;

  mounted(): void {
    this.emailRules = [
      value => {
          if (this.isEmailValid(this.email)) return true
          return this.$t("contactForm.wrongEmail")
        }
    ];
  }

  submitDialog() {
    // TODO: Prodgress, send BE request to contact, then show snackbar, then close()

    if (!this.isEmailValid(this.email)) {
      return;
    }
    const params: Record<string, string> = {
      name: this.name,
      email: this.email,
      message: this.message
    };
    
    this.working = true;
    emailjs.send('service_ybnvuq7', 'template_fyb78wh', params, 'ciAmS41XRaPpCb6T4')
        .then((result) => {
          this.working = false;
          this.showMessage(this.$t("contactForm.success"));
            console.log('Contact email sent: ', result.text);
            this.close();
        }, (error) => {
          this.working = false;
          this.showMessage(this.$t("contactForm.error"));
          console.log('Contact email failed', error.text);
          this.close();
        });
  }

  isEmailValid(email: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  showMessage(text: string) {
    this.snackBarText = text;
    this.showSnackbar = true;
  }

  close() {
    this.show = false;
  }
}
