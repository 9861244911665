
import { inject } from '@vue/runtime-dom';
import router from './router';
import useCookies from './useCookies';

export default {
  data: () => ({ drawer: false }),
  setup() {
    const gtag = inject<any>('gtag');
    const { showBanner, okClicked } = useCookies(gtag);
    const okBannerClicked = () => okClicked();
    const goToHome = () => { router.push("/"); }

    return {
      showBanner,
      okBannerClicked,
      goToHome
    };
  }
}
