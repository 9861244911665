import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pa-container" }
const _hoisted_2 = { class: "pa-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayableAdItem = _resolveComponent("PlayableAdItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('home.portfolio.header')), 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playables, (playable, index) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PlayableAdItem, { playable: playable }, null, 8, ["playable"])
        ]))
      }), 256))
    ])
  ], 64))
}