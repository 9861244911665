
export interface PlayableAdsResponse {
    version: string;
    playables: PlayableAd[];
}

export interface PlayableAd {
    name: string;
    description: string;
    preview: string;
    src: string;
    store?: {
        codester?: string;
        free?: string;
        vpa?: string;
        wip: boolean;
    }
}

export default class ApiService {
    public constructor() {
    }

    async getPlayableAds() : Promise<PlayableAdsResponse> {
        const response = await fetch(`${process.env.VUE_APP_BE_URL}/playable-ads.json`);

        if (!response.ok) {
            return Promise.reject(response.statusText);
        }
        
        return response.json();
    }

    async getDocFile(name: string) : Promise<string> {
        const response = await fetch(`/doc-assets/${name}.md`);

        if (!response.ok) {
            return Promise.reject(response.statusText);
        }
        return response.text();
    }

    async getBlogFile(name: string) : Promise<string> {
        const response = await fetch(`/blog-assets/${name}.md`);

        if (!response.ok) {
            return Promise.reject(response.statusText);
        }
        return response.text();
    }

    public static getAbsoluteUrl(relativeUrl: string) {
        return `${process.env.VUE_APP_BE_URL}/${relativeUrl}`;
    }
}