
import AdList from '@/components/AdList.vue';
import { Options, Vue } from 'vue-class-component';
import Intro from '@/components/Intro.vue'

@Options({
  components: {
    Intro,
    AdList
  },
})
export default class HomeView extends Vue {}
