
import { Options, Vue } from 'vue-class-component';
import ContactFormDialog from './ContactFormDialog.vue';

@Options({
  components: {
    ContactFormDialog
  },
  props: { }
})
export default class Intro extends Vue {
  public dialog: boolean = false;
  openCodester() {
    window.open('https://www.codester.com/randspot/shop', '_blank');
  }

  openContactDialog() {
    this.dialog = true;
  }

  openVPAEditor() {
    window.open('https://app.playablemaker.com', '_blank');
  }
}
